export const fields = [
    { key: 'image', label: "Photo", _style:'min-width:100px' },
    { key: 'full_name', label: "Nama", _style:'min-width:100px' },
    { key: 'username', label: "Username", _style:'min-width:100px' },
    { key: 'email', label: "E-Mail", _style:'min-width:100px' },
    { key: 'member_id', label: "Jabatan", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
