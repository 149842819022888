<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-sm btn-success" @click="addUser()">
                  Tambah User
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
        >
          <template #image="{ item }">
            <td class="py-2">
              <img
                :src="item.image"
                width="80px"
              />
            </td>
          </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton
                @click="changePassword(item)"
                class="mr-2"
                color="info"
                square
                size="sm"
              >
                Change Password
              </CButton>
              <CButton
                v-if="item.id != user.id"
                @click="hapus(item)"
                color="danger"
                square
                size="sm"
              >
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="50"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit User' : 'Tambah User'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.full_name"
            label="Nama Lengkap"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.username"
            label="username"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.email"
            label="Email"
            type="email"
            placeholder="test@email.com"
          />
          <CSelect 
            v-model="form.member_id" 
            label="Jabatan"
            :options="typeOptions" 
            placeholder="Select Type">
          </CSelect>
          <CInput
            v-if="!isUpdate"
            v-model="form.password"
            label="Password"
            type="password"
            placeholder="*******"
          />
          <CInput
            v-if="!isUpdate"
            v-model="form.password_confirmation"
            label="Konfirmasi Password"
            type="password"
            placeholder="ketik disini"
          />
          <div>
            <label class="form-label" for="newData.image">Photo</label>
            <input
              type="file"
              class="form-control mb-2"
              id="newData.image"
              @change="selectImage"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah User
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update User
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      size="lg"
      title="Change Password"
      centered
      color="primary"
      :show.sync="modalPassword"
    >
      <div class="row">
        <div class="col-12">
          <CInput
            v-model="form.full_name"
            label="Nama User"
            placeholder="nama"
            disabled
          />
        </div>
        <div class="col-12">
          <CInput
            v-model="form.password"
            label="Password"
            type="password"
            placeholder="ketik disini"
            :is-valid="isPasswordValid"
            @update:value="inputPassword()"
            :lazy="false"
          />
        </div>
        <div class="col-12">
          <CInput
            v-model="form.password_confirmation"
            label="Konfirmasi Password"
            type="password"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="closeModalPassword" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="updatePassword" class="btn btn-primary">
            Update Password
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/user"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      total: 0,
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
      typeOptions: null,
    }
  },
  methods: {
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.image = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()

      // INSERT KEDALAM FIREBASE
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((userCredential) => {
          this.$toast.success("Berhasil menambahkan user firebase")

          // Proses berhasil masuk di sini
          this.form.firebase_id = userCredential.user.uid
          console.log(this.form.firebase_id)

          // insert ke database
          this.$store
            .dispatch("user/addUser", this.form)
            .then(() => {
              this.$toast.success("Berhasil menambahkan user")
              loading.hide()
              this.createModal = false
              this.form = {}
              this.getData()
            })
            .catch((e) => {
              console.error(e)
              this.$toast.success(e)
              loading.hide()
            })
        })
        .catch((error) => {
          // Tangani kesalahan di sini
          console.error(error.message)
          this.$toast.success(error.message)
        })
    },
    edit(item) {
      this.form = item
      console.log(item);
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("user/updateUser", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data user")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("user/deleteUser", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data user")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("user/getUser", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.items)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()

        })
        .catch((e) => {
          loading.hide()
        })
    },
    addUser() {
      this.isUpdate = false
      this.form = {}
      this.createModal = true
    },
    changePassword(item) {
      this.modalPassword = true
      this.form.id = item.id
      this.form.full_name = item.full_name
      this.form.firebase_id = item.firebase_id
    },
    inputPassword() {
      this.invalidPassword = ""
      this.isPasswordValid = null
    },
    validatePassword(item) {
      if (item.password.length < 6) {
        this.invalidPassword = "Password kurang dari 6 karakter!!"
        this.isPasswordValid = false
        return false
      } else if (item.password != item.password_confirmation) {
        this.invalidPassword = "Konfirmasi password tidak sama!!"
        this.isPasswordValid = false
        return false
      } else {
        this.invalidPassword = ""
        this.isPasswordValid = null
        return true
      }
    },
    updatePassword() {
      if (this.validatePassword(this.form)) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("user/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$toast.success("Berhasil merubah password user")
            loading.hide()
            this.modalPassword = false
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      } else {
        this.$toast.error(this.invalidPassword)
      }
    },
    closeModalPassword() {
      this.form = {}
      this.invalidPassword = ""
      this.isPasswordValid = null
      this.modalPassword = false
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    deleteAccountByUserId(userId) {
      firebase
        .auth()
        .getUser(userId)
        .then((userRecord) => {
          // Hapus akun pengguna dengan metode delete()
          return firebase.auth().deleteUser(userRecord.uid)
        })
        .then(() => {
          // Akun berhasil dihapus
          console.log("Akun berhasil dihapus berdasarkan ID pengguna:", userId)
        })
        .catch((error) => {
          // Tangani kesalahan jika ada
          console.error("Error saat menghapus akun:", error.message)
        })
    },
    getStruktur() {
        var loading = this.$loading.show()
        const params= {
          sorttype: "asc",
          sortby: "id",
          row: 50,
          page: 1,
          keyword: "",
        }
        this.$store
          .dispatch("member/getMember", params)
          .then((resp) => {
            this.typeOptions = resp.data.data.map(item => ({
              value: item.id,
              label: item.name
            }));
            console.log("sini:", this.typeOptions);
  
            // khusus untuk checkbox
            this.selectedItems = []
            this.items.forEach((element) => {
              if (this.isSelectedAll) {
                element.select = true
                this.selectedItems.push(element.id)
              } else {
                element.select = false
              }
            })
            loading.hide()
          })
          .catch((e) => {
            loading.hide()
          })
      },
      getMemberIdFromTypeOptions(type) {
      const typeAsInteger = parseInt(type, 10);
      const selectedType = this.typeOptions.find(option => option.value === typeAsInteger);
      return selectedType ? selectedType.label : "-";
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          ...item,
          member_id: this.getMemberIdFromTypeOptions(item.member_id),
          verificator_name: this.getNameVerificator(item.verificator),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedVerificator() {
      console.log(this.form.verificator)
      if (this.form.verificator || this.form.verificator === 0) {
        let doc = this.verificators.find((element) => {
          return element.value == this.form.verificator
        })
        console.log(doc)
        if (doc) {
          let verificator = {
            value: doc.value,
            label: doc.label,
          }
          this.form.verificators = verificator
        }
      }
      return this.verificators.map((item) => {
        return {
          value: item.value,
          label: item.label,
        }
      })
    },
  },
  mounted() {
    console.log(firebase, "firebase")
    this.getStruktur()
    this.getData()
  },
}
</script>
